<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Check List Inicial</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12">
                            <b-tabs v-model="tabIndex" variant="pills">
                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Alcance de Organizacion
                                            </template>
                                            <b-row class="mt-3">
                                                <b-col md="12" v-if="isTemplate">
                                                    <validation-provider name="Codigo" :rules="{required:true}" v-slot="validationContext">
                                                        <b-form-group label="Código:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model.trim="dataCheckListInicial.codigo" />
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="alcance de la Homologacion" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Alcance de la Homologacion (Linea de productos a homologar):" class="mb-2">
                                                            <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el alcance de la Homologacion" v-model.trim="dataCheckListInicial.jAlcanceOrganizacion.fAlcanceHomologacion"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Ubicacion de la prestacion del servicio" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Ubicacion de la prestacion del servicio:" class="mb-2">
                                                            <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la Ubicacion de la prestacion del servicio" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.fUbicacionPrestacionServicio"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Persona Contacto" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Persona Contacto" class="mb-2">
                                                            <b-form-input placeholder="Ingrese la Persona de Contacto" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.fPersonaContacto"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-form-group label="Sector:" :rules="{required: !isTemplate}" class="mb-2">
                                                        <b-form-checkbox-group v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.sector" :options="[{value: 1, text: 'Bienes:'},{value: 2, text: 'Servicios:'},{value: 3, text: 'Mixta:'}]" small plain></b-form-checkbox-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Antiguedad de la Empresa" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Antiguedad de la Empresa" class="mb-2">
                                                            <b-form-input placeholder="Ingrese la Antiguedad de la Empresa" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.antiguedadempresa"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Visita</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Lugar" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Lugar:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese la Antiguedad de la Empresa" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.lugar"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="Fecha" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.fecha"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="Inicio" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Inicio:" class="mb-2">
                                                            <b-form-input placeholder="Inicio" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.inicio"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="Fin" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Fin:" class="mb-2">
                                                            <b-form-input placeholder="Fin" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.fin"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Evaluador" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Evaluador:" class="mb-2">
                                                            <b-form-input placeholder="Evaluador" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.evaluador"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Accionistas principales de la empresa</u></span>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Nombre" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Nombre:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese el Nombre" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.accionistasprincipalesdelaempresanombre"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Actual" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Actual:" class="mb-2">
                                                            <b-form-input placeholder="Actual" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.accionistasprincipalesdelaempresaactual"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Principal Ejecutivos Gerentes" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Principal Ejecutivos Gerentes:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Principal Ejecutivo de Gerentes" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.principalesejecutivosgerentes"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Partida Electronica/Ficha Registral" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Partida Electronica/Ficha Registral:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Partida Electronica/Ficha Registral" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.partidaelectronicaficharegistral"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Testimonio de Constitucion / Modificacion de Estatutos o Similar" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Testimonio de Constitucion / Modificacion de Estatutos o Similar:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Testimonio de Constitucion / Modificacion de Estatutos o Similar" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.testimoniodeconstitucionmodificaciondeestatuososimiliar"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Acuerdos con otras firmas" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Acuerdos con otras firmas:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Acuerdos con otras firmas" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.acuerdosconotrasfirmas"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Acuerdos de Representacion o Distribucion" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Acuerdos de Representacion o Distribucion:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Acuerdos de Representacion o Distribucion" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.acuerdosderepresentacionodistribucion"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Numero de Licencia de Funcionamiento" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Numero de Licencia de Funcionamiento:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Numero de Licencia de Funcionamiento" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.numerodelicenciadefuncionamiento"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Numero de Certificado de Inspeccion Tecnica de Seguridad en Defensa Civil" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Numero de Certificado de Inspeccion Tecnica de Seguridad en Defensa Civil:" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Numero de Certificado de Inspeccion Tecnica de Seguridad en Defensa Civil" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.numerodecertificadodeinspecciontecnicadeseguridadendefensacivil"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Numero de Registro como Empresa Contratista Minera(si aplica)" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Numero de Registro como Empresa Contratista Minera(si aplica):" class="mb-2">
                                                            <b-form-input placeholder="Ingrese Numero de Registro como Empresa Contratista Minera(si aplica)" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jAlcanceOrganizacion.numeroderegistrocomoempresacontratistaminera"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button :to="{ name: 'Check List de Informacion' }" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Datos de Infraestructura
                                            </template>
                                            <b-row class="mt-4">
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>

                                                        <b-tbody>
                                                            <b-tr>

                                                                <b-td width="10%" class="text-center font-weight-bold">
                                                                    Inmuebles
                                                                </b-td>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    <b-row>

                                                                        <b-col md="6">
                                                                            Propio
                                                                        </b-col>

                                                                        <b-col md="6">
                                                                            Arrendado
                                                                        </b-col>

                                                                    </b-row>

                                                                    <b-row>

                                                                        <b-col md="3">
                                                                            SI/NO
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            Area(m2)
                                                                        </b-col>
                                                                        <b-col md="3">
                                                                            Area(m2)
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            Vigencia del Arriendo/Aql.
                                                                        </b-col>

                                                                    </b-row>

                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Ubicación Geográfica (Dirección, ciudad)
                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Comentarios
                                                                </b-td>

                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Oficinas:
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    <b-row>

                                                                        <b-col md="3">
                                                                            <validation-provider name="SI/NO" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="SI/NO" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[0].sinooficinas"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[0].areaoficinas"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>
                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[0].areamoficinas"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Vigencia" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Vigencial de Arriendo/ Aql." v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[0].vigenciaoficinas"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                    </b-row>
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Ubicacion Geografica" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ubicacion Geografica" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[0].ubicacionoficinas"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>

                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Comentarios" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Comentarios" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[0].comentariosoficinas"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-td>
                                                            </b-tr>

                                                            <b-tr>
                                                                <b-td>
                                                                    Almacén:
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    <b-row>

                                                                        <b-col md="3">
                                                                            <validation-provider name="SI/NO" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="SI/NO" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[1].sinoalmacen"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[1].areaalmacen"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>
                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[1].areamalmacen"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Vigencia del Arriendo/Aql." v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Vigencia del Arriendo/Aql." v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[1].vigenciaalmacen"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                    </b-row>
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Ubicacion Geografica" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ubicacion Geografica" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[1].ubicacionalmacen"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>

                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Comentarios" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Comentarios" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[1].comentariosalmacen"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-td>
                                                            </b-tr>

                                                            <b-tr>
                                                                <b-td>
                                                                    Talleres:
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    <b-row>

                                                                        <b-col md="3">
                                                                            <validation-provider name="SI/NO" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="SI/NO" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[2].sinotalleres"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[2].areatalleres"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>
                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[2].areamtalleres"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Vigencia del Arriendo/Aql." v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Vigencia del Arriendo/Aql." v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[2].vigenciatalleres"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                    </b-row>
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Ubicacion Geografica" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ubicacion Geografica" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[2].ubicaciontalleres"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>

                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Comentarios" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Comentarios" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[2].comentariostalleres"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-td>
                                                            </b-tr>

                                                            <b-tr>
                                                                <b-td>
                                                                    Laboratorio:
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    <b-row>

                                                                        <b-col md="3">
                                                                            <validation-provider name="SI/NO" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="SI/NO" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[3].sinolaboratorio"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[3].arealaboratorio"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>
                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[3].areamlaboratorio"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Vigencia del Arriendo/Aql." v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Vigencia del Arriendo/Aql." v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[3].vigencialaboratorio"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                    </b-row>
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Ubicacion Geografica" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ubicacion Geografica" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[3].ubicacionlaboratorio"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>

                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Comentarios" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Comentarios" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[3].comentarioslaboratorio"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-td>
                                                            </b-tr>

                                                            <b-tr>
                                                                <b-td>
                                                                    Otros (Especificar):
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    <b-row>

                                                                        <b-col md="3">
                                                                            <validation-provider name="SI/NO" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="SI/NO" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[4].sinootros"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[4].areaotros"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>
                                                                        <b-col md="3">
                                                                            <validation-provider name="Area(m2)" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Area(m2)" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[4].areamotros"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                        <b-col md="3">
                                                                            <validation-provider name="Vigencia del Arriendo/Aql." v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Vigencia del Arriendo/Aql." v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[4].vigenciaotros"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>

                                                                    </b-row>
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Ubicacion Geografica" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ubicacion Geografica" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[4].ubicacionotros"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>

                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">

                                                                    <validation-provider name="Comentarios" v-slot="validationContext">
                                                                        <b-form-group label="" class="mb-2">
                                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Comentarios" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.detalle[4].comentariosotros"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-td>
                                                            </b-tr>

                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="Numero de Colaboradores" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Numero de Colaboradores:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Numero de Colaboradores" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.numerodecolabores"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="Administrativos" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Administrativos:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Administrativos" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.administrativos"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="Operativos" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Operativos:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Operativos" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.operativos"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <validation-provider name="Turnos de trabajo y Horarios" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Turnos de trabajo y Horarios:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Turnos de trabajos y Horarios" v-model.lazy="dataCheckListInicial.jDatosInfraestructura.turnodetrabajos"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <validation-provider name="firma">
                                                        <b-input-group>
                                                            <b-input-group-prepend v-if="dataCheckListInicial.urlArchivo != ''">
                                                                <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(dataCheckListInicial.urlArchivo)">
                                                                    <i class="fas fa-download fa-xs"></i>
                                                                </b-button>
                                                            </b-input-group-prepend>
                                                            <b-form-file ref="file" class="text-left" v-model.lazy="dataCheckListInicial.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="dataCheckListInicial.nombreArchivo ? dataCheckListInicial.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="dataCheckListInicial.nombreArchivo ? dataCheckListInicial.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                        </b-input-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Datos de Produccion
                                            </template>
                                            <b-row class="mt-3">
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5">Capacidad de Contratación (Indicar la capacidad de contratación de la línea del servicio que desea homologar. (Ej: Horas/Hombre, Toneladas; Unidades, etc.))</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Linea Productos" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Linea Productos" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la Linea de Producto" v-model.trim="dataCheckListInicial.jDatosProduccion.lineaproducto"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <validation-provider name="Capacidad Instalada" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Capacidad Instalada" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la Capacidad Instalada" v-model.trim="dataCheckListInicial.jDatosProduccion.capacidadinstalada"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <validation-provider name="Produccion Actual" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Produccion Actual" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Produccion Actual" v-model.trim="dataCheckListInicial.jDatosProduccion.produccionactual"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <validation-provider name="%(Produccion actual/Capacidad Instalada)" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="%(Produccion actual/Capacidad Instalada)" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese % (Produccion actual / Capacidad Instalada)" v-model.trim="dataCheckListInicial.jDatosProduccion.produccioncapacidad"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>

                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Datos Activos
                                            </template>
                                            <b-row class="mt-4">

                                                <b-col md="12">
                                                    <b-button class="float-right" size="md" variant="dark" @click="agregarDatoActivo">
                                                        Agregar
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-3">
                                                    <b-table :items="dataCheckListInicial.jListaDatosActivos" :fields="headerDatosActivos" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template v-slot:cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>

                                                        <template v-slot:cell(descripcion)="data">
                                                            <b-form-input placeholder="Ingrese la descripcion" v-model.trim="data.item.descripcion"></b-form-input>
                                                        </template>

                                                        <template v-slot:cell(marca)="data">
                                                            <b-form-input placeholder="Ingrese la marca" v-model.trim="data.item.marca"></b-form-input>
                                                        </template>

                                                        <template v-slot:cell(capacidad)="data">
                                                            <b-form-input placeholder="Ingrese la capacidad" v-model.trim="data.item.capacidad"></b-form-input>
                                                        </template>

                                                        <template v-slot:cell(aniodefabricacion)="data">
                                                            <b-form-input placeholder="Ingrese los años" v-model.trim="data.item.anios"></b-form-input>
                                                        </template>

                                                        <template v-slot:cell(alquileropropiedadformal)="data">
                                                            <b-form-radio-group v-model="data.item.alquiler" plain :options="[{value: 1, text: 'SI'},{value: 2,text: 'NO'}]"></b-form-radio-group>
                                                        </template>

                                                        <template v-slot:cell(opciones)="param">
                                                            <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarDatosActivos(param)" variant="danger">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>

                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer4" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Declaracion de Sistemas
                                            </template>
                                            <b-row>
                                                <b-col md="12">
                                                    <br />
                                                </b-col>

                                                <b-col md="12">

                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>

                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>

                                                            </b-tr>

                                                            <b-tr>

                                                                <b-td>
                                                                    Cuenta con equipos de computo y software especializado para el desarrollo de sus actividades
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionSistema[0].pregunta1" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>

                                                            </b-tr>

                                                            <b-tr>

                                                                <b-td>
                                                                    Los software utilizados tienen la licencia correspondiente
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionSistema[0].pregunta2" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>

                                                            </b-tr>

                                                            <b-tr>

                                                                <b-td>
                                                                    Realiza copias de seguridad (backup) de su información
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionSistema[0].pregunta3" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>

                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer5" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Declaracion de Personal
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Cantidad de Persona</span>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="Fijo" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Fijo" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Fijo de Cantidad Personal" v-model.lazy="dataCheckListInicial.jDeclaracionPersonal.fijopersonal"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="SubContrata" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Subcontrata" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese SubContrata de Cantidad Personal" v-model.lazy="dataCheckListInicial.jDeclaracionPersonal.subcontratapersonal"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="Total" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Total" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Total de Cantidad de Personal" v-model.lazy="dataCheckListInicial.jDeclaracionPersonal.totalpersonal"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Antigüedad Promedio (años)</span>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="Fijo" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Fijo" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Fijo de Antiguedad Promedio" v-model.lazy="dataCheckListInicial.jDeclaracionPersonal.fijopromedio"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="Subcontrata" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Subcontrata" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese SubContrata de Antiguedad Promedio" v-model.lazy="dataCheckListInicial.jDeclaracionPersonal.subcontratapromedio"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="N/A" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="N/A" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese N/A de Antiguedad Promedio" v-model.lazy="dataCheckListInicial.jDeclaracionPersonal.napromedio"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>

                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer6" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>

                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Informacion Financiera
                                            </template>

                                            <b-row>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Estado Financieros</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Balance y el Estado de Pérdidas y Ganancias (2 últimos años)- Declarados a SUNAT o auditados.</label>
                                                </b-col>
                                                <!--ESTADO FINANCIERO-->
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    2018
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    2019
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    2020
                                                                </b-td>
                                                            </b-tr>
                                                            <!--N° DE DIAS-->
                                                            <b-tr>
                                                                <b-td>
                                                                    Nº de días
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[0].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[0].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[0].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Periodo
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[1].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[1].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[1].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Activo Corriente
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[2].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[2].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[2].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Activo No Corriente
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[3].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[3].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[3].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Total Activo
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[4].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[4].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[4].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Pasivo Corriente
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[5].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[5].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[5].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Pasivo no Corriente
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[6].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[6].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[6].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Total Pasivo
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[7].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[7].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[7].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Patrimonio
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[8].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[8].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[8].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Cuadre
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[9].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[9].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[9].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Ventas
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[10].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[10].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[10].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Costo de Ventas
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[11].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[11].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[11].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Utilidad
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[12].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[12].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[12].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Utilidad Neta del Ejercicio
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[13].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[13].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[13].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Cuentas por cobrar
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[14].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[14].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[14].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Cuentas por pagar
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[15].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[15].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[15].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Capital Social
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[16].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[16].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[16].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Existencias
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[17].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[17].dato2"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.estadosFinancieros[17].dato3"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Ratios de liquidez</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td>
                                                                    Razón de Liquidez Gral
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosLiquidez[0].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosLiquidez[0].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Prueba Ácida
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosLiquidez[1].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosLiquidez[1].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Activo No Cte. / Pasivo No Cte.
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosLiquidez[2].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosLiquidez[2].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Ratios de Gestión (días)</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td>
                                                                    Rotación de Stocks
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosGestion[0].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosGestion[0].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Rotación de Cuentas por Cobrar
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosGestion[1].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosGestion[1].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Rotación de Cuentas por Pagar
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosGestion[2].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosGestion[2].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <!--RATIOS DE SOLVENCIA-->
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Ratios de Solvencia</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td>
                                                                    Razón de endeudamiento
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosSolvencia[0].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosSolvencia[0].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Ratios de Rentabilidad</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td>
                                                                    Rentabilidad sobre Patrimonio
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosPatrimonio[0].dato1"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-input rows="3" max-rows="6" placeholder="" v-model.trim="dataCheckListInicial.jInformacionFinanciera.ratiosPatrimonio[0].dato2"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                                <b-col md="12" class="mb-2">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="20%">
                                                                    Explicación de ratios
                                                                </b-td>
                                                                <b-td width="80%">
                                                                    <b-row>
                                                                        <b-col md="12">
                                                                            <label>(1) Indica el grado de cobertura que tienen los activos de mayor liquidez sobre las obligaciones de menor vencimiento o mayor exigibilidad. Cuanto más elevado sea el coeficiente alcanzado, mayor será la capacidad de la empresa para satisfacer las deudas que vencen a corto </label>
                                                                        </b-col>

                                                                        <b-col md="12">
                                                                            <label>(2) Representa una medida más directa de la solvencia financiera de corto plazo de la empresa, al tomar en consideración los niveles de liquidez de los componentes del activo circulante. Cuanto más elevado sea el coeficiente mayor será el grado de liquidez de la empresa.</label>
                                                                        </b-col>

                                                                        <b-col md="12">
                                                                            <label>(3) Indica el número de días que, en promedio, los productos terminados permanecen dentro de los inventarios que mantiene la empresa.</label>
                                                                        </b-col>

                                                                        <b-col md="12">
                                                                            <label>(4) Determina el número de días que en promedio transcurre entre el momento en que se realiza la venta y el momento en que se hace efectiva la cobranza.</label>
                                                                        </b-col>

                                                                        <b-col md="12">
                                                                            <label>(5) Determina el número de días que en promedio transcurre entre el momento que se realiza la compra y el momento en que se hace efectivo el pago.</label>
                                                                        </b-col>

                                                                        <b-col md="12">
                                                                            <label>(6) Indicador o media del riesgo financiero. Depende de la política financiera que aplica la empresa. Cuanto mayor sea el indicador, mayor será el riesgo de la empresa.</label>
                                                                        </b-col>

                                                                        <b-col md="12">
                                                                            <label>(7) Mide la rentabilidad de los accionistas, considerando el apalancamiento financiero.</label>
                                                                        </b-col>
                                                                    </b-row>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                                <b-col md="12">
                                                    <b-form-group label="La empresa presentó los dos últimos estados financieros anuales:">
                                                        <b-form-radio-group small plain id="radio-group-su" v-model.lazy="dataCheckListInicial.jInformacionFinanciera.pregunta1" :options="RButtonInformacionFinanciera"></b-form-radio-group>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer7" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Obligaciones de la Organizacion
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">Obligaciones y Protestos</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Lista de Obligaciones Bancarias a Corto y Largo Plazo (Sobregiros, Pagarés, Letras) al día</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Tipo de Obligacion" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Tipo de Obligacion (Vigente):" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Tipo de Obligacion" v-model.lazy="dataCheckListInicial.jObligacionesOrganizacion.tipoobligacion"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Monto en US$" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Monto en US$:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el Monto en US$" v-model.lazy="dataCheckListInicial.jObligacionesOrganizacion.monto"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Se encuentra al dia en los pagos" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Se encuentra al dia en los pagos:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese si se encuentra al dia en los pagos" v-model.lazy="dataCheckListInicial.jObligacionesOrganizacion.diapagos"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>(1) Indicar el tipo de cambio</label>
                                                    <label>(2) Completado por el evaluador </label>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer8" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Declaracion de obligaciones
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mb-2 mt-2">
                                                    <span class="text-muted h5">De acuerdo al reporte de INFOCORP al:</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    La empresa registra obligaciones vencidas y no pagadas
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionObligaciones.pregunta1" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Tiene procesos Administrativos o Judiciales pendientes (Especificar)
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionObligaciones.pregunta2" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    La calificación de la SBS considera a la empresa como normal
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionObligaciones.pregunta3" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer9" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Cuentas Bancos
                                            </template>
                                            <b-row>
                                                <b-col md="6" class="mb-2 mt-4">
                                                    <span class="text-muted h5"><u>Bancos con los que trabaja su Empresa</u></span>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-button size="md" variant="dark" class="mb-3 mt-3 float-right" @click="agregarCuentaBanco">
                                                        Agregar nueva cuenta
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="dataCheckListInicial.jCuentaBancos" :fields="headerCuentaBancos" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template v-slot:cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(banco)="data">
                                                            <b-form-input type="text" v-model="data.item.banco" />
                                                        </template>
                                                        <template v-slot:cell(sectoria)="data">
                                                            <b-form-input type="text" v-model="data.item.sectoria" />
                                                        </template>
                                                        <template v-slot:cell(nrocuenta)="data">
                                                            <b-form-input type="text" v-model="data.item.nrocuenta" />
                                                        </template>
                                                        <template v-slot:cell(ajuste)="param">
                                                            <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCuentaBanco(param)" variant="danger">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>

                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer10" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Declaracion de Tributos y Obligaciones Laborales
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5">Cumplimiento de sus obligaciones en los ultimos seis meses</span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    SUNAT
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionTributos.pregunta1" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ESSALUD
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionTributos.pregunta2" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    AFP´s y/o ONP
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionTributos.pregunta3" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Planilla de personal
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jDeclaracionTributos.pregunta4" plain :options="RButtonDeclaracionSistemas"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer11" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Lista de Principales Clientes
                                            </template>
                                            <b-row>
                                                <b-col md="8" class="mt-3 mb-2">
                                                    <label>Cinco principales clientes de los Productos / Servicios que brinda a Cliente</label>
                                                </b-col>
                                                <b-col md="4" class="mt-2 mb-2">
                                                    <b-button size="md" variant="dark" class="float-right" @click="agregarPrincipalCliente">
                                                        Agregar Cliente
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="dataCheckListInicial.jListaPrincipalesClientes" :fields="headerPrincipalesClientes" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template v-slot:cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(razonsocial)="data">
                                                            <b-form-input type="text" v-model="data.item.razonsocial" />
                                                        </template>
                                                        <template v-slot:cell(sectoractividad)="data">
                                                            <b-form-input type="text" v-model="data.item.sectoractividad" />
                                                        </template>
                                                        <template v-slot:cell(antiguedad)="data">
                                                            <b-form-input type="text" v-model="data.item.antiguedad" />
                                                        </template>
                                                        <template v-slot:cell(contacto)="data">
                                                            <b-form-input type="text" v-model="data.item.contacto" />
                                                        </template>
                                                        <template #cell(ajuste)="param">
                                                            <b-button @click="eliminarPrincipalCliente(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer12" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Gestion de Calidad
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion de la Calidad</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión de la Calidad certificado?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaCalidad.pregunta1" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión de la Calidad basado en ISO 9001:2015?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaCalidad.pregunta2" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="Si cuenta con un SGC certificado" v-slot="validationContext">
                                                        <b-form-group label="Si cuenta con un SGC certificado, indicar:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Indicar si cuenta" v-model.lazy="dataCheckListInicial.jSistemaCalidad.sgccertificado"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Norma" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Norma:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Norma" v-model.lazy="dataCheckListInicial.jSistemaCalidad.norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Alcance" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Alcance:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Alcande" v-model.lazy="dataCheckListInicial.jSistemaCalidad.alcance"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Vigencia" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Vigencia:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Vigencia" v-model.lazy="dataCheckListInicial.jSistemaCalidad.vigencia"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Entidad Certificadora" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Entidad Certificadora:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Entidad Certificadora" v-model.lazy="dataCheckListInicial.jSistemaCalidad.entidad"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaCalidad')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaCalidad')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>

                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer13" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Mantenimiento y Calibración
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion de la Calidad</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaMantenimiento')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaMantenimiento')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer14" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Compra, Recepcion y Almacenes
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Compra, Recepcion y Almacenes</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad, Ambiente y SST.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaCompras')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaCompras')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer15" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Procesos SubContratados
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Procesos Sub Contratados</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaProcesoSub')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaProcesoSub')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer16" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Procesos Productivo
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Procesos Productivo</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaProcesoProd')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaProcesoProd')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer17" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Gestion del Personal
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion del Personal</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad, SST y MA.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaPersonal')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaPersonal')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer18" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Gestion Comercial
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion Comercial</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Calidad.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaComercial') " :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaComercial')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer19" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Gestion de Seguridad y Salud en el Trabajo
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion de Seguridad y Salud en el Trabajo</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para SST.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>
                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión de Seguridad y Salud en el Trabajo certificado?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaSeguridad.pregunta1" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión de la SST basado en ISO 45001:2018?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaSeguridad.pregunta2" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="sicuentaconSGSSTcertificado" v-slot="validationContext">
                                                        <b-form-group label="Si cuenta con un SGSST certificado, indicar:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaSeguridad.sgsstcerficado" placeholder="Indicar"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Norma" v-slot="validationContext">
                                                        <b-form-group label="Norma:" :rules="{required: !isTemplate}" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaSeguridad.norma" placeholder="Norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Alcance" v-slot="validationContext">
                                                        <b-form-group label="Alcance:" :rules="{required: !isTemplate}" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaSeguridad.alcance" placeholder="Norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Vigencia" v-slot="validationContext">
                                                        <b-form-group label="Vigencia:" :rules="{required: !isTemplate}" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaSeguridad.vigencia" placeholder="Norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="EntidadCertificadora" v-slot="validationContext">
                                                        <b-form-group label="Entidad Certificadora:" :rules="{required: !isTemplate}" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaSeguridad.entidad" placeholder="Norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaSeguridad')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaSeguridad')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer20" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Gestion Ambiental
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion Ambiental</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Valido para Ambiental.</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión Ambiental certificado?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaAmbiental.pregunta1" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>

                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión Ambiental basado en ISO 14001:2015?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaAmbiental.pregunta2" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                                <b-col md="12">
                                                    <validation-provider name="sicuentaconSGAcertificado" v-slot="validationContext">
                                                        <b-form-group label="Si cuenta con un SGA certificado, indicar:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAmbiental.sgacertificado" placeholder="Indicar"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="Norma" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Norma:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAmbiental.norma" placeholder="Ingrese Norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Alcance" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Alcance:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAmbiental.alcance" placeholder="Ingrese Alcance"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Vigencia" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Vigencia:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAmbiental.vigencia" placeholder="Ingrese Vigencia"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="Entidad Certificadora" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Entidad Certificadora:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAmbiental.entidad" placeholder="Ingrese Entidad Certificadora"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaAmbiental')" :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaAmbiental')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>

                                <validation-observer ref="observer21" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(registrarPackCheckListInicial)">
                                        <b-tab>
                                            <template slot="title">
                                                <i class="fas fa-cog  fa-md"></i> Sistema de Gestion Antisoborno
                                            </template>
                                            <b-row>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <span class="text-muted h5"><u>Gestion Antisoborno</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <label>Válido para Antisoborno</label>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" class="text-center font-weight-bold">
                                                                    Pregunta
                                                                </b-td>

                                                                <b-td width="20%" class="text-center font-weight-bold">
                                                                    Evaluacion
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión Antisoborno certificado?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaAntisoborno.pregunta1" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    ¿La empresa tiene un Sistema de Gestión Antisoborno basado en ISO 37001:2016?:
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group v-model="dataCheckListInicial.jSistemaAntisoborno.pregunta2" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="sicuentaconSGAScertificado" v-slot="validationContext">
                                                        <b-form-group label="Si cuenta con un SGAs certificado, indicar:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAntisoborno.sgascertificado" placeholder="Indicar"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="Norma" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Norma:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAntisoborno.norma" placeholder="Ingrese Norma"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="Alcance" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Alcance:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAntisoborno.alcance" placeholder="Ingrese Alcance"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="Vigencia" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Vigencia:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAntisoborno.vigencia" placeholder="Ingrese Vigencia"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="Entidad Certificadora" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Entidad Certificadora:" class="mb-2">
                                                            <b-form-input rows="3" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataCheckListInicial.jSistemaAntisoborno.entidad" placeholder="Ingrese Entidad Certificadora"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-table :items="listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == 'jSistemaAntisoborno') " :fields="headerPreguntasSistemas" bordered hover show-empty mediun responsive outlined empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #table-colgroup="headerPreguntasSistemas">
                                                            <col v-for="field in headerPreguntasSistemas.fields" :key="field.key" :style="{ width: `${field.width}` }">
                                                        </template>
                                                        <template v-slot:cell(enunciado)="data">
                                                            <b-form-textarea type="text" rows="3" v-model="data.item.enunciado" />
                                                        </template>
                                                        <template v-slot:cell(evaluacion)="data">
                                                            <b-form-radio-group v-if="!data.item.hasTitulo" v-model="data.item.evaluacion" plain :options="RButtonGestionCalidad"></b-form-radio-group>
                                                        </template>
                                                        <template v-slot:cell(urlArchivo)="data">
                                                            <b-input-group v-if="!data.item.hasTitulo">
                                                                <b-input-group-prepend v-if="data.item.urlArchivo != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="downloadFile(data.item.urlArchivo)">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="data.item.metaArchivo" v-on:change="handleFileUpload($event)" :placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="data.item.nombreArchivo ? data.item.nombreArchivo: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                            </b-input-group>
                                                        </template>
                                                        <template v-slot:cell(puntaje)="data">
                                                            <b-form-select v-if="!data.item.hasTitulo" value-field="descripcion" text-field="descripcion" v-model="data.item.puntaje" :options="comboPuntaje">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </template>
                                                        <template #cell(opcion)="param">
                                                            <b-button @click="eliminarSistema(param,'jSistemaAntisoborno')" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>

                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button @click="anterior" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Siguiente <i class="fas fa-arrow-right"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-form>
                                </validation-observer>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            isTemplate: true,
            comboPuntaje: [{
                descripcion: '0'
            }, {
                descripcion: '30'
            }, {
                descripcion: '60'
            }, {
                descripcion: '90'
            }, {
                descripcion: '100'
            }, ],

            headerDatosActivos: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                {
                    key: 'descripcion',
                    label: 'Descripcion',
                    class: 'text-center',
                },
                {
                    key: 'marca',
                    label: 'Marca',
                    class: 'text-center',
                },
                {
                    key: 'capacidad',
                    label: 'Capacidad',
                    class: 'text-center',
                },
                {
                    key: 'aniodefabricacion',
                    label: 'Año de Fabricacion',
                    class: 'text-center',
                },
                {
                    key: 'alquileropropiedadformal',
                    label: 'Alquiler o propiedad formal(SI/NO)',
                    class: 'text-center',
                },
                {
                    key: 'opciones',
                    label: '',
                    class: 'text-center',
                }
            ],
            headerCuentaBancos: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                {
                    key: 'banco',
                    label: 'Bancos',
                    class: 'text-center',
                },
                {
                    key: 'sectoria',
                    label: 'Sectoria',
                    class: 'text-center',
                },
                {
                    key: 'nrocuenta',
                    label: 'Nro de Cuenta',
                    class: 'text-center',
                },
                {
                    key: 'ajuste',
                    label: '',
                    class: 'text-center',
                },
            ],
            headerPrincipalesClientes: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                {
                    key: 'razonsocial',
                    label: 'Razon Social',
                    class: 'text-center',
                },
                {
                    key: 'sectoractividad',
                    label: 'Sector de actividad del cliente',
                    class: 'text-center',
                },
                {
                    key: 'antiguedad',
                    label: 'Antiguedad (años)',
                    class: 'text-center',
                },
                {
                    key: 'contacto',
                    label: 'Contacto',
                    class: 'text-center',
                },
                {
                    key: 'ajuste',
                    label: '',
                    class: 'text-center',
                },
            ],
            headerPreguntasSistemas: [{
                    key: 'enunciado',
                    label: 'Pregunta',
                    class: 'text-center',
                    width: '30%',
                },
                {
                    key: 'evaluacion',
                    label: 'Evaluacion',
                    class: 'text-center',
                    width: '15%',
                },
                {
                    key: 'urlArchivo',
                    label: 'Cargar Archivo',
                    class: 'text-center',
                    width: '30%',
                },
                {
                    key: 'puntaje',
                    label: 'Puntaje',
                    class: 'text-center',
                    width: '20%',
                },
                {
                    key: 'opcion',
                    label: '',
                    class: 'text-center',
                    width: '5%',
                },
            ],
            RButtonDeclaracionSistemas: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                },
                {
                    value: 3,
                    text: 'NA'
                },
            ],
            RButtonInformacionFinanciera: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                }
            ],
            RButtonGestionCalidad: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                },
                {
                    value: 3,
                    text: 'NO APLICA'
                },
            ],
            RButtonDatosActivos: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                }
            ],
            dataCheckListInicial: {
                idPackCheckListInicial: null,
                idAuditoria: null,
                idCliente: '',
                codigo: '',
                metaArchivo: null,
                urlArchivo: '',
                nombreArchivo: '',
                jAlcanceOrganizacion: {
                    fAlcanceHomologacion: '',
                    fUbicacionPrestacionServicio: '',
                    fPersonaContacto: '',
                    sector: [],
                    antiguedadempresa: '',
                    lugar: '',
                    fecha: moment().format('YYYY-MM-DD'),
                    inicio: '',
                    fin: '',
                    evaluador: '',
                    accionistasprincipalesdelaempresanombre: '',
                    accionistasprincipalesdelaempresaactual: '',
                    principalesejecutivosgerentes: '',
                    partidaelectronicaficharegistral: '',
                    testimoniodeconstitucionmodificaciondeestatuososimiliar: '',
                    acuerdosconotrasfirmas: '',
                    acuerdosderepresentacionodistribucion: '',
                    numerodelicenciadefuncionamiento: '',
                    numerodecertificadodeinspecciontecnicadeseguridadendefensacivil: '',
                    numeroderegistrocomoempresacontratistaminera: '',
                },
                jDatosInfraestructura: {
                    detalle: [{
                        sinooficinas: '',
                        areaoficinas: '',
                        areamoficinas: '',
                        vigenciaoficinas: '',
                        ubicacionoficinas: '',
                        comentariosoficinas: '',
                    }, {
                        sinoalmacen: '',
                        areaalmacen: '',
                        areamalmacen: '',
                        vigenciaalmacen: '',
                        ubicacionalmacen: '',
                        comentariosalmacen: '',
                    }, {
                        sinotalleres: '',
                        areatalleres: '',
                        areamtalleres: '',
                        vigenciatalleres: '',
                        ubicaciontalleres: '',
                        comentariostalleres: '',
                    }, {
                        sinolaboratorio: '',
                        arealaboratorio: '',
                        areamlaboratorio: '',
                        vigencialaboratorio: '',
                        ubicacionlaboratorio: '',
                        comentarioslaboratorio: '',
                    }, {
                        sinootros: '',
                        areaotros: '',
                        areamotros: '',
                        vigenciaotros: '',
                        ubicacionotros: '',
                        comentariosotros: '',
                    }],

                    numerodecolabores: '',
                    administrativos: '',
                    operativos: '',
                    turnodetrabajos: '',
                },
                jDatosProduccion: {
                    lineaproducto: '',
                    capacidadinstalada: '',
                    produccionactual: '',
                    produccioncapacidad: '',
                },
                jListaDatosActivos: [{
                    descripcion: '',
                    marca: '',
                    capacidad: '',
                    anio: '',
                    alquiler: 0,
                }],
                jDeclaracionSistema: [{
                    pregunta1: 0,
                    pregunta2: 0,
                    pregunta3: 0,
                }],
                jDeclaracionPersonal: {
                    fijopersonal: '',
                    subcontratapersonal: '',
                    totalpersonal: '',
                    fijopromedio: '',
                    subcontratapromedio: '',
                    napromedio: '',
                },
                jInformacionFinanciera: {
                    estadosFinancieros: [{
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }, {
                        dato1: '',
                        dato2: '',
                        dato3: '',
                    }],

                    ratiosLiquidez: [{
                        dato1: '',
                        dato2: '',
                    }, {
                        dato1: '',
                        dato2: '',
                    }, {
                        dato1: '',
                        dato2: '',
                    }],

                    ratiosGestion: [{
                        dato1: '',
                        dato2: '',
                    }, {
                        dato1: '',
                        dato2: '',
                    }, {
                        dato1: '',
                        dato2: '',
                    }],

                    ratiosSolvencia: [{
                        dato1: '',
                        dato2: '',
                    }],

                    ratiosPatrimonio: [{
                        dato1: '',
                        dato2: '',
                    }],

                    pregunta1: 0,
                },
                jObligacionesOrganizacion: {
                    tipoobligacion: '',
                    monto: 0,
                    diapagos: '',
                },
                jDeclaracionObligaciones: {
                    pregunta1: 0,
                    pregunta2: 0,
                    pregunta3: 0,
                },
                jCuentaBancos: [{
                    banco: '',
                    sectoria: '',
                    nrocuenta: '',
                }],
                jDeclaracionTributos: [{
                    pregunta1: 0,
                    pregunta2: 0,
                    pregunta3: 0,
                    pregunta4: 0,
                }],
                jListaPrincipalesClientes: [],

                jSistemaCalidad: {
                    pregunta1: 0,
                    pregunta2: 0,
                    sgccertificado: '',
                    norma: '',
                    alcance: '',
                    vigencia: '',
                    entidad: '',
                },
                jSistemaMantenimiento: {},
                jSistemaCompras: {},
                jSistemaProcesoSub: {},
                jSistemaProcesoProd: {},
                jSistemaPersonal: {},
                jSistemaComercial: {},
                jSistemaSeguridad: {
                    pregunta1: 0,
                    pregunta2: 0,
                    sgsstcerficado: '',
                    norma: '',
                    alcance: '',
                    vigencia: '',
                    entidad: '',
                },
                jSistemaAmbiental: {
                    pregunta1: 0,
                    pregunta2: 0,
                    sgacertificado: '',
                    norma: '',
                    alcance: '',
                    vigencia: '',
                    entidad: '',
                },
                jSistemaAntisoborno: {
                    pregunta1: 0,
                    pregunta2: 0,
                    sgascertificado: '',
                    norma: '',
                    alcance: '',
                    vigencia: '',
                    entidad: '',
                },
            },
            disabled: false,
            tabIndex: 0,

            listaDocumentacionPreguntas: [{
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha designado un representante de la dirección para asegurar que se haya establecido y se mantenga el sistema de gestión de calidad?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se han definido las especificaciones de los productos / servicios ofertados?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han definido especificaciones de materiales, ingredientes o procesos?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Cuando algún material o propiedad del cliente se encuentra temporalmente en las instalaciones de la empresa, este material está plenamente identificado y se toman las precauciones requeridas para evitar su deterioro?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa tiene un proceso escrito e implementado, para controlar las salidas no conformes?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa tiene un proceso escrito e implementado para la generación de acciones correctivas y acciones preventivas?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa tiene un proceso escrito e implementado para la realización de auditorias internas, el mismo que incluye registros de auditorías internas y resultados? Pueden ser Integradas con otros sistemas',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han tenido auditorías internas / externas para comprobar la eficiencia del Sistema, y que hayan incluído todos los procesos? Pueden ser Integradas con otros sistemas',
                    evaluacion: 0,
                    puntaje: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha realizado monitoreos de satisfacción del Cliente e implementado acciones de mejora?		',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCalidad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se realiza un registro del mantenimiento correctivo de la maquinaria / equipa?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaMantenimiento'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen implantado un programa de mantenimiento preventivo?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaMantenimiento'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen definido e implantado un programa de calibración de los instrumentos de medición?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaMantenimiento'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Los equipos de medición se encuentran identificados con etiquetas que indiquen cuando fueron calibrados y cuando es su próxima calibración?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaMantenimiento'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La calibración de los instrumentos de medición se realiza con patrones trazables?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaMantenimiento'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'La empresa ha implementado un procedimiento sistemático para seleccionar a sus proveedores (de productos y/o servicios), incluyendo criterios de calidad, ambiente y SST?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha implementado un procedimiento sistemático para evaluar periódicamente el desempeño de sus proveedores?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen implantado un procedimiento sistemático de inspección de los productos comprados?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Solicitan certificados de calidad por lote de productos adquiridos?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el almacén, ¿Los productos tienen identificación y se mantiene un control de stock de los mismos?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el almacén, ¿Se encuentra organizado y su capacidad es la adecuada, para la cantidad de productos almacenados?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el almacén, ¿Tiene definida un área de productos no conformes?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el almacén,¿Tienen hojas de seguridad de los productos almacenados, cuando estos representan algún tipo de riesgo para la salud o el medioambiente?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el almacén, ¿Se realizan inventarios periódicos?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaCompras'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa exige a sus subcontratistas el cumplimiento de los mismos estándares de trabajo requeridos por Cliente?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoSub'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa informa al subcontratista las condiciones mínimas indispensables para la SST y ambiente que deben cumplir en sus instalaciones o en las de Cliente?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoSub'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Los productos comercializados por la empresa han sido fabricados de acuerdo a una norma nacional o internacional?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Realizan una planificación y programación de las actividades de comercialización?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen especificaciones escritas del producto, identificadas como vigentes y han sido formalmente proporcionadas al Cliente?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen implantado un procedimiento sistemático para realizar el control de calidad durante el proceso de comercialización?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Cuenta con documentos que permitan realizar una trazabilidad / seguimiento / rastreo del producto comercializado?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen implantado un procedimiento sistemático para realizar el control de la actividad de distribución?. Incluyendo, si corresponde, condiciones de embalaje, rutas de transporte, entrega del producto en buen estado, etc.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el caso que la empresa venda a Cliente, un material o insumo que forme parte del producto final, ¿estos cumplen con: inspección visual, etc.?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En el caso que la empresa venda a Cliente, un material o insumo que forme parte del producto final, ¿Los productos cumplen requisitos legales?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaProcesoProd'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha definido las competencias para las diferentes funciones de la organización? (Por ejm.: Perfiles).',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa difunde la política y objetivos de calidad, SST y MA al personal?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han implementado un procedimiento sistemático de reclutamiento de personal para evaluar el cumplimiento del perfil requerido?. En caso la respuesta sea positiva, presentar los registros que acrediten esta evaluación.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa tiene un Manual de Funciones?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen un programa de capacitación del personal, basado en las necesidades de capacitación detectadas?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿En caso tuvieran un programa de capacitación, este se cumple?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa mantiene archivos del personal en el cual se evidencie la educación, formación y experiencia?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaPersonal'
                },
                {
                    idPackCheckListDocumento: 0,
                    enunciado: 'Previo a la realización de la venta, la empresa evalúa si está en condiciones de satisfacer los requerimientos del cliente (en cuanto a calidad, cantidad y plazo de entrega), proporcionándole alguno de los siguientes documentos:',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: true,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'a) Cotización / Proforma',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'b) Propuesta técnica',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'c) Catálogo',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa evalúa la satisfacción de sus clientes, guardando registros de dicha actividad?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'En caso evalúe la satisfacción del cliente, ¿Toma acciones basadas en el resultado de la evaluación realizada?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen implantado un procedimiento sistemático para la atención de quejas u observaciones de los clientes?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa registra las quejas / observaciones de los clientes y genera una estadística de las mismas?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Es posible conocer el plazo de entrega y verificar el cumplimiento del mismo, luego de concluído el servicio / la venta / la entrega del producto?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tiene establecido un procedimiento de trabajo sistemático para afrontar retrasos en la ejecución y finalización del servicio / fabricación y/o entrega del producto ?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Cuentan con un plan de contingencia frente a un problema?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Brinda sus productos / servicios con garantía? Periodo.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa brinda algún tipo de soporte técnico a sus clientes?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa dispone de medios adecuados de distribución para la entrega del producto/servicio final al cliente?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Cuentan con sistemas que aseguren la confidencialidad en el acceso a datos personales?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaComercial'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa tiene una Política de Salud y Seguridad Ocupacional enunciada, aprobada por la gerencia general y difundida al personal?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Posee un Reglamento de Seguridad y Salud Ocupacional? (si tiene más de 25 empleados)	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se ha realizado una identificación de peligros y evaluación de riesgos en el área de trabajo e instalaciones?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han definido un listado de tareas peligrosas cuyo riesgo deba ser controlado?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Tienen implantados los siguientes procedimientos de seguridad y salud ocupacional?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: true,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'a) Manipuleo / almacenaje de productos químicos / inflamables',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'b) Tareas Críticas (trabajos en altura, caliente, espacios confinados, excavación / exploración, trabajos eléctricos o con otro tipo de energía peligrosa)	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'c) Seguridad en operación de vehículos / grúas / montacargas, etc.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'd) Investigación de accidentes y enfermedades ocupacionales	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'e) Planes de emergencia (fuga, derrame, Matpel, incendios, sismos)	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'f) Dictado de charlas de seguridad.	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿En la empresa cumplen con las siguientes medidas de seguridad?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: true,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'a) Uso, mantenimiento e inspección de extintores / medios de extinción con carga vigente(en oficinas, vehículos, equipos pesados, frentes de trabajo, etc.)	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'b) Uso, Mantenimiento e inspección de equipos de protección personal',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'c) Señalización (advertencia, prohibiciones, obligación, información general), restricción de acceso a áreas de riesgo',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'd) Equipos de primeros auxilios',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'e) Simulacros de emergéncia (Sismo, incendio, derrames, y/o fugas de sustancias)',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'f) Programa de entrenamiento en Seguridad y Salud Ocupacional.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿El personal conoce los riesgos a los cuales está expuesto en cada una de las actividades que realiza?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Los EPP cumplen con alguna norma internacional?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'Tienen un programa de salud que incluya:	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: true,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'a) Seguro médico',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'b) Atención médica solo en caso de accidente',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'c) Elaboración de línea base en salud ocupacional.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'd) Evaluación médica en función de la línea base',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'e) Otros	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha identificado las normas legales que le aplican, relacionadas a Seguridad y Salud Ocupacional y les hacen seguimiento al cumplimiento?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaSeguridad'
                },
                {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha establecido y difundido al personal, una Política Ambiental?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La organización ha definido una metodología para la identificación de aspectos ambientales significativos?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Ha identificado los aspectos ambientales significativos?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La organización ha definido e implementado Controles Operacionales para los aspectos ambientales significativos y cuentan con los procedimientos documentados correspondientes?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han definido un Plan de Emergencia Ambiental?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Que tipo de residuo genera su actividad?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa genera residuos peligrosos? (Ejm Tubos fluorescentes, pilas, baterias, material impregnado con grasa) - Orientación informativa	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Cuál es el tipo de disposición de estos residuos?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: true,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'a) Reciclados',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'b) Dispuestos al vertedero municipal',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'c) Reutilizados	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'd) Otros',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'Sus descargas de efluentes son a:	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: true,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'a) Alcantarillado',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'b) Cámara séptica',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'c) Tratamiento de efluente propio',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'd) Tratamiento de efluente de tercero	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'e) Canal pluvial',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: 'f) Otros',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha elaborado e implementado un programa de entrenamiento para la Gestión Ambiental?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Si la empresa cuenta con vehículos para la actividad homologada, estos vehículos tienen certificado de opacidad o de emisiones, dentro del rango establecido por ley?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha identificado las normas legales que le aplican, relacionadas a Ambiente y les hacen seguimiento al cumplimiento?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAmbiental'
                },
                {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha definido un Órgano de Gobierno por encima de la Alta Dirección?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa tiene una Política Antisoborno enunciada, aprobada por la gerencia general y difundida al personal?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Posee un Reglamento Interno u otro documento donde establesca la identificación, prevención y lucha contra el soborno?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se ha realizado una identificación de riesgos de soborno asociados a las operaciones de la organización?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han designado una Función de Cumplimiento para la implementación, seguimiento y reporte del SGAs?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Han establecido acciones para tratar los riesgos y oportunidades Antisoborno?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha identificado las normas legales que le aplican, relacionadas al delito de soborno y les hacen seguimiento al cumplimiento?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se cuenta con un procedimiento para la debida diligencia sobre el personal que ocupe cargos con riesgo mas que bajo de soborno?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿La empresa ha elaborado e implementado un programa de entrenamiento para la Gestión Antisoborno?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se ha establecido la debida diligencia en los procesos, negocios y socios de negocio con riesgo mas que bajo?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se han establecido controles financieros? Ejemplos	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se han establecido controles no financieros? Ejemplos	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se han establecido controles a socios de negocio y organizaciones controladas?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se han establecido compromisos antisoborno con organizaciones no controladas?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se ha establecido una política respecto a los regalos, atenciones, donaciones y beneficios similares?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se han establecido las acciones a tomar ante la insuficiencia en los controles antisoborno?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Cuentan con un canal para la atención de inquietudes? (respecto al soborno)',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se cuenta con un procedimiento para la investigación y tratamiento de actos de soborno?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se realiza la revisión por el Organo de Gobierno? Si existiese el OdG.',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se realiza la revisión por la función de cumplimiento?	',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }, {
                    idPackCheckListDocumento: 0,
                    enunciado: '¿Se toman acciones correctivas ante la identificación de no conformidades en el sistma de gestión antsoborno?',
                    evaluacion: 0,
                    puntaje: null,
                    metaArchivo: null,
                    urlArchivo: '',
                    nombreArchivo: '',
                    hasTitulo: false,
                    estado: 2,
                    tipo: 'jSistemaAntisoborno'
                }
            ]
        };
    },
    methods: {
        avanzar() {
            this.tabIndex += 1;
        },
        anterior() {
            this.tabIndex -= 1;
        },
        registrarPackCheckListInicial() {
            let me = this;
            const formData = new FormData();
            formData.append("idCliente", me.dataCheckListInicial.idCliente);
            formData.append("folder", 'pack/documentos-inicial');
            formData.append("dataCheckListInicial", JSON.stringify(me.dataCheckListInicial));
            formData.append("filey", me.dataCheckListInicial.metaArchivo);

            let data = 0;
            for (const i in me.listaDocumentacionPreguntas) {
                if (me.listaDocumentacionPreguntas[i].metaArchivo != null && me.listaDocumentacionPreguntas[i].idPackCheckListDocumento == 0) {
                    data = data - 1;
                    formData.append("filex" + data, me.listaDocumentacionPreguntas[i].metaArchivo);
                } else {
                    formData.append("filex" + me.listaDocumentacionPreguntas[i].idPackCheckListDocumento, me.listaDocumentacionPreguntas[i].metaArchivo);
                }
            }

            formData.append("listaDocumentacionPreguntas", JSON.stringify(me.listaDocumentacionPreguntas));

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;

            axios.post(
                CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/registrar-pack-checklist-inicial",
                formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                },
            ).then(function (response) {
                let color = response.data.resultado == 1 ? "success" : "error";
                me.swat(color, response.data.mensaje);
                me.$router.push({
                    name: 'Check List de Informacion'
                })
            }).catch(function (e) {
                console.log(e);
            });
        },
        agregarDatoActivo() {
            this.dataCheckListInicial.jListaDatosActivos.push({
                descripcion: '',
                marca: '',
                capacidad: '',
                anio: '',
                alquiler: 0,
            });
        },
        eliminarDatosActivos(param) {
            this.dataCheckListInicial.jListaDatosActivos.splice(param.index, 1);
        },
        agregarCuentaBanco() {
            this.dataCheckListInicial.jCuentaBancos.push({
                banco: '',
                sectoria: '',
                nrocuenta: '',
            });
        },
        eliminarCuentaBanco(param) {
            this.dataCheckListInicial.jCuentaBancos.splice(param.index, 1);
        },
        agregarPrincipalCliente() {
            this.dataCheckListInicial.jListaPrincipalesClientes.push({
                razonsocial: '',
                sectoractividad: '',
                antiguedad: '',
                contacto: ''
            });
        },
        eliminarPrincipalCliente(param) {
            this.dataCheckListInicial.jListaPrincipalesClientes.splice(param.index, 1);
        },
        eliminarSistema(param, sistema) {
            let lstFilter = this.listaDocumentacionPreguntas.filter(el => el.estado == 2 && el.tipo == sistema);
            if (this.$route.params.id) {
                if (lstFilter[param.index].idPackCheckListDocumento != 0) {
                    lstFilter[param.index].estado = 1
                } else if (!lstFilter[param.index].idDocumento) {
                    lstFilter[param.index].estado = 0
                    for (let e in this.listaDocumentacionPreguntas) {
                        if (this.listaDocumentacionPreguntas[e].estado == 0) {
                            this.listaDocumentacionPreguntas.splice(e, 1);
                        }
                    }
                }
            } else {
                lstFilter[param.index].estado = 0;
                for (let e in this.listaDocumentacionPreguntas) {
                    if (this.listaDocumentacionPreguntas[e].estado == 0) {
                        this.listaDocumentacionPreguntas.splice(e, 1);
                    }
                }
            }

        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        obtenerCheckListInicial() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-pack-checklist-inicial", {
                    params: {
                        idPackCheckListInicial: me.$route.params.id,
                        idCliente: me.dataCheckListInicial.idCliente
                    }
                })
                .then(function (response) {
                    me.dataCheckListInicial.idPackCheckListInicial = response.data[0][0].idPackCheckListInicial;
                    me.dataCheckListInicial.idAuditoria = response.data[0][0].idAuditoria;
                    me.dataCheckListInicial.idCliente = response.data[0][0].idCliente;
                    me.dataCheckListInicial.codigo = response.data[0][0].codigo;
                    me.dataCheckListInicial.urlArchivo = response.data[0][0].urlArchivo;
                    me.dataCheckListInicial.nombreArchivo = response.data[0][0].nombreArchivo;
                    me.dataCheckListInicial.jAlcanceOrganizacion = JSON.parse(response.data[0][0].jAlcanceOrganizacion);
                    me.dataCheckListInicial.jDatosInfraestructura = JSON.parse(response.data[0][0].jDatosInfraestructura);
                    me.dataCheckListInicial.jDatosProduccion = JSON.parse(response.data[0][0].jDatosProduccion);
                    me.dataCheckListInicial.jListaDatosActivos = JSON.parse(response.data[0][0].jListaDatosActivos);
                    me.dataCheckListInicial.jDeclaracionSistema = JSON.parse(response.data[0][0].jDeclaracionSistema);
                    me.dataCheckListInicial.jInformacionFinanciera = JSON.parse(response.data[0][0].jInformacionFinanciera);
                    me.dataCheckListInicial.jObligacionesOrganizacion = JSON.parse(response.data[0][0].jObligacionesOrganizacion);
                    me.dataCheckListInicial.jDeclaracionObligaciones = JSON.parse(response.data[0][0].jDeclaracionObligaciones);
                    me.dataCheckListInicial.jCuentaBancos = JSON.parse(response.data[0][0].jCuentaBancos);
                    me.dataCheckListInicial.jDeclaracionTributos = JSON.parse(response.data[0][0].jDeclaracionTributos);
                    me.dataCheckListInicial.jListaPrincipalesClientes = JSON.parse(response.data[0][0].jListaPrincipalesClientes);
                    me.dataCheckListInicial.jSistemaCalidad = JSON.parse(response.data[0][0].jSistemaCalidad);
                    me.dataCheckListInicial.jSistemaMantenimiento = JSON.parse(response.data[0][0].jSistemaMantenimiento);
                    me.dataCheckListInicial.jSistemaCompras = JSON.parse(response.data[0][0].jSistemaCompras);
                    me.dataCheckListInicial.jSistemaProcesoSub = JSON.parse(response.data[0][0].jSistemaProcesoSub);
                    me.dataCheckListInicial.jSistemaProcesoProd = JSON.parse(response.data[0][0].jSistemaProcesoProd);
                    me.dataCheckListInicial.jSistemaPersonal = JSON.parse(response.data[0][0].jSistemaPersonal);
                    me.dataCheckListInicial.jSistemaComercial = JSON.parse(response.data[0][0].jSistemaComercial);
                    me.dataCheckListInicial.jSistemaSeguridad = JSON.parse(response.data[0][0].jSistemaSeguridad);
                    me.dataCheckListInicial.jSistemaAmbiental = JSON.parse(response.data[0][0].jSistemaAmbiental);
                    me.dataCheckListInicial.jSistemaAntisoborno = JSON.parse(response.data[0][0].jSistemaAntisoborno);
                    me.listaDocumentacionPreguntas = response.data[1]

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: 'Check List de Informacion'
                    })
                });
        },

        downloadFile(url) {
            window.open(url)
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.dataCheckListInicial.idCliente = user.uidClient;
            if (this.$route.params.id) {
                this.obtenerCheckListInicial();
            }
        }
    },
}
</script>
